.white
{
    --preloader-color : #fff;
}
.dark
{
    --preloader-color : var(--secondary-color);
}
.primary
{
    --preloader-color: var(--primary-color);
}
.preloader-container
{
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 33px;
    justify-content: center;
    align-items: center;
    position: relative;

    overflow: hidden;
}
.lds-ring {
    display: inline-block;
    position: relative;
    width: 50px;
    height: 50px;
}
.lds-ring:after {
    content : "";
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 34px;
    height: 34px;
    margin: 8px;
    border: 2px solid var(--preloader-color);
    border-radius: 50%;
    animation: rota 1.5s infinite linear;
    border-top-color: transparent;
}

/* Animation */
@keyframes rota {
    from { }
    to { transform: rotate(360deg); }
}