.main-menu {
  position: fixed;
  top: 0;
  right: -25em;
  width: 30em;
  height: 100vh;
  background: #fff;
  z-index: 100;
  transition: right 0.2s;
}

body.main-menu-is-open .main-menu {
  right: 0;
}

.main-menu-icon {
  position: absolute;
  width: 2.5em;
  height: 2.5em;
  background: #fff;
  border: 0;
  left: 1.25em;
  top: 50%;
  transform: translateY(-50%);
}

.main-menu-icon > div {
  position: absolute;
  width: 100%;
  height: 2px;
  background: var(--primary-color);
  border-radius: 2em;
  left: 0;
  top: 0;
}

.main-menu-icon > div:first-child {
  top: 0.7em;
  transform-origin: left;
}

.main-menu-icon > div:last-child {
  top: 1.6em;
  transform-origin: left;
}

body.main-menu-is-open .main-menu .main-menu-icon > div:first-child {
  top: 0.4em;
  left: 0.4em;
  transform: rotate(45deg);
}

body.main-menu-is-open .main-menu .main-menu-icon > div:last-child {
  top: 2.19em;
  left: 0.4em;
  transform: rotate(-45deg);
}

.menu-wrap {
    position: relative;
    width: 25em;
    min-height: 100vh;
    margin-left: 5em;  
    border-left: 1px solid var(--primary-border-color);
  }
  
  .menu-wrap .item {
    position: absolute;
    width: 12.5em;
    height: 12.5em;
    top: 12.5em;
    border-bottom: 1px solid var(--primary-border-color);
    border-collapse: collapse;
    cursor: pointer;
    color: #333;
  }
  
  .menu-wrap .item .inner {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 6em;
    font-size: 1.2em;
  }
  
  .menu-wrap .item .inner:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 3em;
    top: -3.4em;
    left: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 2.4em;
  }
  
  .menu-wrap .item.home {
    width: 100%;
    left: 0;
    top: 0;  
  }
  
  .menu-wrap .item.location {
    left: 0;
    border-right: 1px solid var(--primary-border-color);
  }
  
  .menu-wrap .item.gallery {
    left: 12.5em;
  }
  
  .menu-wrap .item.brochure {
    top: 25em;
    border-right: 1px solid var(--primary-border-color);
  }
  
  .menu-wrap .item.filter {
    top: 25em;
    left: 12.5em;
  }
.menu-wrap .item.home .inner {
  top: 7em;
}
  .menu-wrap .item.home .inner:before {
    background-image: url('../img/icon-logo.svg');
    background-size: 40%;
    height: 7rem;
    top: -5.5rem;
  }
  
  .menu-wrap .item.location .inner:before {
    background-image: url('../img/icon-location.svg');
  }
  
  .menu-wrap .item.gallery .inner:before {
    background-image: url('../img/icon-gallery.svg');
  }
  
  .menu-wrap .item.brochure .inner:before {
    background-image: url('../img/icon-brochure.svg');
  }
  
  .menu-wrap .item.filter .inner:before {
    background-image: url('../img/icon-filter.svg');
  }

  .menu-wrap .menu-footer {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 2em;

  }

  .menu-wrap .menu-footer .login {
    display: block;
    margin: 0 auto;
  }


@media (min-width: 1680px) {

  
  
}
