.submenu-wrap {
    position: relative;
    width: 25em;
    min-height: 100vh;
    margin-left: 5em;
    border-left: 1px solid var(--primary-border-color);

}
.submenu-wrap .inner {
    display: flex;
    align-items: center;
    min-height: 100vh;
}
.submenu
{
    width: 100%;
}
.submenu-item
{
    display: block;
    width: 100%;
    padding: 1.2em;
    text-transform: uppercase;
    border-top : 1px solid var(--primary-border-color);
    color: var(--secondary-color);
    font-size: 1.2em;
    line-height: 1.2em;

}

.submenu-item.last
{
    border-bottom: 1px solid var(--primary-border-color);
}

