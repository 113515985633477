.dashboard-table {
    position: relative;
    background: #fff;
    padding: 1rem;
    border-radius: 0.428571429rem;
    margin-bottom: 30px;
    width: 100%;
    height: 40vh;
    overflow: hidden;
}
.dashboard-table.h-80
{
    height: 80vh;
}
.dashboard-table .loading-overlay
{
    height: 100%;
    background: #fff;
}

.dashboard-table .inner {
    /* height: 20em; */
    /* overflow: auto; */
}

.dashboard-table h3 {
    text-transform: uppercase;
    font-size: 1.2em;
    padding-left: 0.75rem;
    margin-bottom: 0.7rem;
}

.dashboard-table a:hover {
    text-decoration: underline;
}

.dashboard-table.property-actions table td:last-child {
    text-transform:  uppercase;
}


.dashboard-table thead,
.dashboard-table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.dashboard-table tbody {
    display: block;
    overflow-y: auto;
    table-layout: fixed;
    max-height: calc(40vh - 8em);
}

.dashboard-table .header h3 {
    display: inline-block;
    border: 0;
}

.dashboard-table .header-button {
    float: right;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 0.1em;
    cursor: pointer;
}

.dashboard-table td.action {
    position: relative;
}



