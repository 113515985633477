.property-view
{
    display: flex;
    height: 100%;
    align-items: center;
}

.main-container
{
    position: relative;
    width: 100%;
    border-top: 1px solid var(--primary-border-color);
    border-bottom: 1px solid var(--primary-border-color);
    height: 70%;
}

.property-details-container
{
    display: flex;
    height: 100%;
    align-items: center;
}
.property-details
{
    padding: 2.5em;
}
.property-title {
    font-size: 3em;
}
.property-title span {
    font-size: 1.8rem;
    font-weight: 100;
    color :#333
}
.property-price
{
    display: none;
    font-size:2.5em;
}

.property-info {
    font-size: 1.2em;
}

.action-buttons-container
{
    padding: 3rem 0px;
}
.action-buttons-container button
{
    display: inline-block;
    margin-right:1rem
}


.bottom-buttons {
    position: absolute;
    left : 2.5em;
    bottom: 2.5em;

}
.floor-plan-button,.tour-360-button
{
    color : var(--primary-color);
    cursor:pointer;
    display:inline-block;
    margin-right: 1.5rem;
}

.status-label
{
    position: absolute;
    top: 0;
    right: 3em;

    background: #fff;
    font-size: 1.4em;
    padding: 0.5em 1.5em;
    z-index: 3;
}
