.viewer360 {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.viewer360 iframe {
    width: 100%;
    height: 100%;
}