.list-view {
  padding: 2rem;
  position: relative;

}
.list-view:not(.loading)
{
  height: auto !important;
}


.list-view .inner {
  background: #fff;
}

.list-view table tr {cursor:pointer}

.table .thead-dark th {
  color: #fff;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}