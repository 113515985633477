.floor-plan .main-title {
  position: fixed;
  left: 2.5em;
  top: 2.5em;
  color: var(--primary-color);
  z-index: 20;
}

.floor-plan .main-title .number {
  font-size: 2.5em;
  line-height: 1em;
  font-weight: bold;
}

.floor-plan svg {
    position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 60vw;
  height: 60vh;
}

.floor-plan svg  .cls-1{fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:0.5px;}
.floor-plan svg  .cls-2{fill:red;}
.floor-plan svg  .cls-3{fill:#d7ff31;}

.floor-plan .inner .item {
  cursor: pointer;
}
  
.floor-plan .inner svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 90%;
}

.floor-plan .inner svg .cls-2 {
  fill: #444;
  opacity: 0;
  transition: opacity 0.4s;
}

.floor-plan .inner .item:hover .cls-2 {
  fill: #444;
  opacity: 0.1;
  transition: opacity 0.2s;
}

.floor-plan .inner .item text {
  fill: #444;
  stroke: none;
  font-size: 1em;

  text-anchor: middle;
  dominant-baseline: middle;
}

.floor-plan .inner .item text.title {
  transform: translateY(-0.6em);
}

.floor-plan .inner .item text.subtitle {
  transform: translateY(0.6em);
  font-size: 0.75em;
  fill: #777;
}

.floor-plan .inner .item circle {
  transform: translateY(1.7em);
  fill: #ccc;
  r: 5px;
}

.floor-plan .inner svg g {
  position:relative;
}

.floor-plan .inner .item.selected .cls-2 {
  opacity: 0.1;
}

.floor-plan .inner .item.disabled {
  opacity: 0.2;
  cursor: default;
}

.floor-plan .inner .item.disabled:hover .cls-2 {
  opacity: 0;
}
.floor-plan.filter-active .inner .item.filtered .cls-2
{
  opacity: .1;
}
.floor-plan .inner .item.available circle,
.floor-plan .inner .item.available:hover .cls-2,
.floor-plan .inner .item.available .cls-2,
.floor-plan.filter-active .inner .item.available .cls-2 {
  fill: #2eb72e;
}
.floor-plan .inner .item.sold circle,
.floor-plan .inner .item.sold .cls-2,
.floor-plan.filter-active .inner .item.sold .cls-2 {
  fill: #f00;
}
.floor-plan .inner .item.reserved circle,
.floor-plan .inner .item.reserved .cls-2,
.floor-plan.filter-active .inner .item.reserved .cls-2 {
  fill: #ffb600;
}
.color-info{
  position: absolute;
  left: 2.5rem;
  bottom:2.5rem;
}
.color-info span
{
  position: relative;
  padding-left: 1.4rem;
  display: inline-block;
  margin-right: 1.5rem;
}
.color-info span:before
{
  content: '';
  display: block;
  position: absolute;
  top: 0.2rem;
  left: 0;
  width: 0.9rem;
  height: 0.9rem;
  border-radius: 50%;
  background: #777;

}
.color-info span.available:before {background: #2eb72e;}
.color-info span.sold:before {background: #f00;}
.color-info span.reserved:before {background: #ffb600;}