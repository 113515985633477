.client-menu {
    background: rgba(0,0,0,0.8);
    border-radius: 5rem;
    padding: 0.5rem 1.2rem;
    position: absolute;
    left: -15rem;
    bottom: 2rem;
    min-width: 12.5rem;
    color: #fff;
    text-align: center;
}
.client-menu.large
{
    left: -16rem;
}
.text-with-icon
{
    display: inline-block;
    position: relative;
    margin-left: 2.5rem;
    line-height: 2.2rem;

    font-weight: bold;
    margin-right: 1rem;

}
.text-with-icon:before
{
    content: '';
    position: absolute;
    left: -2.5rem;
    width: 2rem;
    height: 2rem;

}
.client-login-button
{
    cursor: pointer;
}
.client-login-button:before
{
    background: url('../img/icon-client-login.svg') no-repeat center;
}
.active-client:before
{
    background: url('../img/icon-client-active.svg') no-repeat center;
}

.goto-fav-button{
    position:relative;
    display: inline-block;
    width: 2.3rem;
    height: 2.3rem;
    background: var(--primary-color);
    border-radius: 50%;
    cursor: pointer;
    transform: scale(0.8);
}
.goto-fav-button:before
{
    content : '';
    position: absolute;
    left:0.55rem;
    top:0.6rem;
    width: 1.2rem;
    height: 1.2rem;
    background: url(../img/icon-like-light.svg) no-repeat center;
}
.goto-fav-button:hover
{
    background: #fff;
}
.goto-fav-button:hover:before
{
    background: url(../img/icon-like.svg) no-repeat center;
}

