.modal.show {
    display: block;
}

.modal-backdrop {
    display: none;
}

.modal-backdrop.show {
    display: block;
}

.modal-content {
    border-radius: 0.428571429rem;
}

.modal-header,
.modal-footer {
    border: 0;
}

.modal-header {
    padding: 0.6rem 1rem;
}

.modal-footer {
    display: block;
    text-align: center;
}

.modal-body {
    margin-top: -1em;
    font-size: 1.2em;
    text-align: center;
}

.modal-body h3 {
    font-size: 1.2em;
}

.modal .default-button {
    display: inline-block;
    font-size: 0.85em;
    margin: 1em;
    min-width: 8em;
    text-align: center;
    padding: 1em;
}

.modal.edit-note textarea {
    min-height: 8em;
}

.modal.edit-person .modal-body,
.modal.edit-user .modal-body,
.modal.assign-person .modal-body,
.modal.edit-property .modal-body {
    font-size: 1em;
}

.modal.edit-person .modal-body-title,
.modal.edit-user .modal-body-title,
.modal.assign-person .modal-body-title,
.modal.edit-property .modal-body-title {
    text-transform: uppercase;
}

.modal.edit-person .modal-body input,
.modal.edit-person .modal-body select,
.modal.edit-user .modal-body input,
.modal.edit-user .modal-body select,
.modal.assign-person .modal-body input,
.modal.assign-person .modal-body select,
.modal.edit-property .modal-body input,
.modal.edit-property .modal-body select {
    font-size: 1em;
}

.modal.edit-person .modal-body select,
.modal.edit-user .modal-body select,
.modal.assign-person .modal-body select,
.modal.edit-property .modal-body select  {
    margin-top: 1em;
}

.modal.edit-person .modal-body input,
.modal.edit-user .modal-body input,
.modal.assign-person .modal-body input,
.modal.edit-property .modal-body input {
    border-radius: 0;
    border-width: 0 0 1px 0;
    padding: 1em 0;
}

.modal .form-group {
    padding: 0 2em;
    text-align: left;
}

.modal .form-group label {
    font-size: 1em;
    font-weight: bold;
    margin: 1rem 0 0;
}